<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  data() {
    return {
      gradient: null,
      gradient2: null
    };
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    // console.log('area', this.item.data, this.item.index)
    // this.renderChart(
    //   {
    //     labels: this.item.labels,
    //     datasets: [
    //       {
    //         label: "ร้านค้า",
    //         borderColor: "#EF6352",
    //         pointBackgroundColor: "white",
    //         borderWidth: 1,
    //         pointBorderColor: "white",
    //         backgroundColor: '#F7B1A9',
    //         data: this.item.data[0]
    //       },
    //       {
    //         label: "ลูกค้า",
    //         borderColor: "#D64A39",
    //         pointBackgroundColor: "white",
    //         pointBorderColor: "white",
    //         borderWidth: 1,
    //         backgroundColor: '#FBD8D4',
    //         data: this.item.data[1]
    //       }
    //     ]
    //   },
    //   { responsive: true, maintainAspectRatio: false }
    // );
    this.renderChart(this.chartData, this.options);
  }
};
</script>
