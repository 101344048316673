
<script>
import { Pie, mixins } from 'vue-chartjs';
export default {
  extends: Pie,
  // props: ["itemData"],
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    // this.renderChart({
    //   labels: this.itemData.labels,
    //   datasets: [
    //     {
    //       // label: 'Data One',
    //       backgroundColor: ['#436294', '#5B76A1', '#7289AF', '#8A9DBC', '#A1B1C9', '#B9C4D7', '#D0D8E4'],
    //       data: this.itemData.data
    //     }
    //   ]
    // }, {responsive: true, maintainAspectRatio: false})
    this.renderChart(this.chartData, this.options)
  }
}
</script>
