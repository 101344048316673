<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="mb-4">
                    <label class="form-label">ร้านค้า</label>
                    <select class="form-control"
                            v-model="store_id" @change="searchDataHandler()">
                      <option v-for="(store, index) in item_stores"
                              :key="index"
                              :value="store.id">
                              {{store.text}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">ยอดรวมการสั่งซื้อของแต่ละร้าน</h3>
                  <b-tabs content-class="mt-3"
                          align="right">
                    <b-tab title="วันธรรมดา" active>
                      <div>
                        <pie :chartData="days" :options="{responsive: true, maintainAspectRatio: true}" chart-id="days"/>
                      </div>
                    </b-tab>
                    <b-tab title="วันหยุด">
                      <div>
                        <pie :chartData="days_off" :options="{responsive: true, maintainAspectRatio: true}" chart-id="days_off"/>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">สินค้า</h3>

                  <el-table class="table-responsive table"
                            :data="products"
                            header-row-class-name="thead-light">
                    <el-table-column label="รหัสสินค้า"
                                    min-width="130px"
                                    prop="product_code"
                                    align="center">
                    </el-table-column>
                    <el-table-column label="ชื่อสินค้า"
                                    min-width="130px"
                                    prop="product_name">
                    </el-table-column>
                    <el-table-column label="จำนวนซัพพลายเออร์"
                                    min-width="200px"
                                    prop="count_supplier"
                                    align="right">
                    </el-table-column>

                    <el-table-column label="จำนวนร้านค้า"
                                    min-width="200px"
                                    prop="count_store"
                                    align="right">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="row">
                <div class="col-sm-6">
                  <div class="card mb-4 custom-card">
                    <div  class="card-body text-center"
                          style="border: 2px solid #FFC1B5;
                          border-radius: 5px;">
                      <div class="icon icon-shape text-white rounded-circle shadow" style="background: #FFC1B5;">
                        <i class="fas fa-suitcase-rolling" style="font-size: 25px; color: #FF6347;"></i>
                      </div>
                      <p class="text-light mb-1 mt-3">สินค้า</p>
                      <h2>{{countData.productCount | number-int-format}}</h2>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="card mb-4 custom-card">
                    <div  class="card-body text-center"
                          style="border: 2px solid #A1E1DE;
                          border-radius: 5px;">
                      <div class="icon icon-shape text-white rounded-circle shadow" style="background: #A1E1DE;">
                        <i class="fas fa-shopping-cart" style="font-size: 22px; color: #42C2BD"></i>
                      </div>
                      <p class="text-light mb-1 mt-3">สั่งซื้อ</p>
                      <h2>{{countData.orderCount | number-int-format}}</h2>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="card mb-4 custom-card">
                    <div  class="card-body text-center"
                          style="border: 2px solid #A5D3FF;
                          border-radius: 5px;">
                      <div class="icon icon-shape text-white rounded-circle shadow" style="background: #A5D3FF;">
                        <i class="fas fa-briefcase" style="font-size: 23px; color: #1E90FF;"></i>
                      </div>
                      <p class="text-light mb-1 mt-3">ซัพพลายเออร์</p>
                      <h2>{{countData.supplierCount | number-int-format}}</h2>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="card mb-4 custom-card">
                    <div  class="card-body text-center"
                          style="border: 2px solid #CAC3F8;
                          border-radius: 5px;">
                      <div class="icon icon-shape text-white rounded-circle shadow" style="background: #CAC3F8;">
                        <i class="fas fa-store" style="font-size: 22px; color: #9586F1;"></i>
                      </div>
                      <p class="text-light mb-1 mt-3">ร้านค้า</p>
                      <h2>{{countData.storeCount | number-int-format}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">สั่งซื้อ</h3>
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                          <div class="mb-4">
                            <label class="form-label">ตั้งแต่วันที่</label>
                            <date-picker  :format="'DD/MM/YYYY'"
                                          v-model="start"
                                          class="w-100"
                                          input-class="form-control form-control-sm"
                                          placeholder="ตั้งแต่วันที่"
                                          :disabled-date="disabledStartDate"
                                          :append-to-body="false"
                                          :popup-style="{left: 0}"
                                          @change="getListOrder()"></date-picker>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-4">
                            <label class="form-label">ถึง</label>
                            <date-picker  :format="'DD/MM/YYYY'"
                                          v-model="end"
                                          class="w-100"
                                          input-class="form-control form-control-sm"
                                          placeholder="ถึง"
                                          :disabled-date="disabledEndDate"
                                          :append-to-body="false"
                                          :popup-style="{left: 0}"
                                          @change="getListOrder()"></date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-tabs content-class="mt-3"
                          align="right">
                    <b-tab title="รายวัน" active>
                      <div>
                        <area-chart :chartData="chartDailyData"
                                    :options="{responsive: true, maintainAspectRatio: true}"
                                    :chart-id="`dayly`"/>
                      </div>
                    </b-tab>
                    <b-tab title="รายเดือน">
                      <div>
                        <area-chart :chartData="chartMonthlyData"
                                    :options="{responsive: true, maintainAspectRatio: true}"
                                    :chart-id="`monthly`"/>
                      </div>
                    </b-tab>
                    <b-tab title="รายสัปดาห์">
                      <div>
                        <area-chart :chartData="chartWeeklyData"
                                    :options="{responsive: true, maintainAspectRatio: true}"
                                    :chart-id="`weekly`"/>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">อัพเซลล์</h3>
                  <el-table
                    class="table-responsive table"
                    :data="upsales"
                    header-row-class-name="thead-light">
                    <el-table-column label="รหัสการสั่งซื้อ"
                                    min-width="150px"
                                    prop="order_code"
                                    align="center">
                    </el-table-column>
                    <el-table-column label="ชื่อลูกค้า"
                                    min-width="130px"
                                    prop="customer_name"
                                    align="center">
                    </el-table-column>

                    <el-table-column label="รหัสสินค้า"
                                    min-width="130px"
                                    prop="product_code"
                                    align="center">
                    </el-table-column>>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">สั่งซื้อ วันนี้</h3>
                    <el-table class="table-responsive table"
                              :data="orders"
                              header-row-class-name="thead-light">
                      <el-table-column label="รหัสการสั่งซื้อ"
                                      min-width="150"
                                      prop="page"
                                      align="center">
                        <template v-slot="{row}">
                          <div class="font-weight-600">{{row.page}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="ชื่อลูกค้า"
                                      min-width="130px"
                                      prop="visitors">
                      </el-table-column>
                      <el-table-column label="จำนวนเงิน"
                                      min-width="140px"
                                      prop="unique"
                                      align="right">
                      </el-table-column>

                      <el-table-column label="จัดส่ง"
                                      min-width="120px"
                                      prop="bounceRate">
                      </el-table-column>
                    </el-table>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">ร้านค้า</h3>
                  <el-table
                    class="table-responsive table"
                    :data="stores"
                    header-row-class-name="thead-light">
                    <el-table-column label="ชื่อร้านค้า" min-width="160px" prop="name">
                      <template v-slot="{row}">
                        <div class="font-weight-600">{{row.name}}</div>
                      </template>
                    </el-table-column>

                    <el-table-column label="ซัพพลายเออร์ที่ผูกกับร้านค้า" min-width="250px" prop="visitors" align="center">
                    </el-table-column>

                    <el-table-column min-width="220px" prop="progress" label="สินค้าที่ผูกกับร้านค้า" class="d-flex align-items-center">
                      <template v-slot="{row}">
                          <span class="mr-2">{{row.progress}}%</span>
                          <base-progress :type="row.progressType" :value="row.progress" />
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>

            <!-- <div class="col-12">
              <div class="card mb-4 custom-card">
                <div class="card-body">
                  <h3 class="mb-0">ข้อมูลการแจ้งยอด</h3>
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                          <div class="mb-4">
                            <label class="form-label">ตั้งแต่วันที่</label>
                            <date-picker  :format="'DD/MM/YYYY'"
                                          v-model="sale_start"
                                          class="w-100"
                                          input-class="form-control form-control-sm"
                                          placeholder="ตั้งแต่วันที่"
                                          :disabled-date="disabledSaleStartDate"
                                          :append-to-body="false"
                                          :popup-style="{left: 0}"></date-picker>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-4">
                            <label class="form-label">ถึง</label>
                            <date-picker  :format="'DD/MM/YYYY'"
                                          v-model="sale_end"
                                          class="w-100"
                                          input-class="form-control form-control-sm"
                                          placeholder="ถึง"
                                          :disabled-date="disabledSaleEndDate"
                                          :append-to-body="false"
                                          :popup-style="{left: 0}"></date-picker>
                          </div>
                        </div>
                      </div>

                      <bar-chart  :item="sales"
                                  chart-id="sales"
                                  :funcs="funcs"/>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui';
import pie from './view/pieChart';
import * as chartConfigs from '@/components/Charts/config';
import areaChart from './view/areaChart';
import * as scripts from '../../util/scripts';
import barChart from './view/barChart';
import DateUtils from '../../util/dateUtils';
import Constants from '../../util/constants';
import NumberUtils from '../../util/numberUtils';
export default {
  name: 'dashboard-view-index',
  data () {
    return {
      dataInterval: null,
      products: [],
      days: {
        labels: [],
        data: []
      },
      days_off: {
        labels: [],
        data: []
      },
      monthly: {
        labels: Constants.months,
        data: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ]
      },
      weekly: {
        labels: ['จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.', 'อา.'],
        data: [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0]
        ]
      },
      upsales: [],
      orders: [],
      stores: [],
      dayly: {
        labels: ['09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00'],
        data: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ]
      },
      item_stores: [],
      store_id: '',
      start: '',
      end: '',
      funcs: scripts.funcs,
      sale_start: '',
      sale_end: '',
      sales: {
        labels: ['LYF', 'FNF', 'WNW', 'WRE', 'LEW', 'WST', 'AFR', 'WTB'],
        data: [
          [0, 20, 5, 25, 10, 30, 15, 40, 28, 19, 32],
          [16, 32, 45, 17, 2, 21, 53, 11, 5, 23, 61]
        ]
      },
      countData: {
        orderCount: 0,
        productCount: 0,
        storeCount: 0,
        supplierCount: 0
      },
      chartDailyData: {
        labels: [],
        datasets: [
          {
            label: "ร้านค้า",
            borderColor: "#EF6352",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: '#F7B1A9',
            data: []
          },
          {
            label: "ลูกค้า",
            borderColor: "#D64A39",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#FBD8D4',
            data: []
          }
        ]
      },
      chartWeeklyData: {
        labels: [],
        datasets: [
          {
            label: "ร้านค้า",
            borderColor: "#EF6352",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: '#F7B1A9',
            data: []
          },
          {
            label: "ลูกค้า",
            borderColor: "#D64A39",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#FBD8D4',
            data: []
          }
        ]
      },
      chartMonthlyData: {
        labels: [],
        datasets: [
          {
            label: "ร้านค้า",
            borderColor: "#EF6352",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: '#F7B1A9',
            data: []
          },
          {
            label: "ลูกค้า",
            borderColor: "#D64A39",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 1,
            backgroundColor: '#FBD8D4',
            data: []
          }
        ]
      }
    }
  },
  methods: {
    async searchDataHandler(){
      await this.getCountData();
      await this.getTotalOrderStore();
      await this.getListProduct();
      await this.getOrderCurrentDay();
      await this.getListStore();
      await this.getListUpsale();
      await this.getListOrder();
    },
    async getCountData () {
      const params = `storeId=${this.store_id}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/count?${params}`);
      this.countData = result;
    },
    async getTotalOrderStore () {
      const params = `storeId=${this.store_id}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/totalOfStore?${params}`);
      const holidayData = result.holidayData;
      const normalData = result.normalData;

      let labels = [];
      let data = [];
      normalData.forEach(v=>{
        labels.push(v.name);
        data.push(v.total);
      });
      // this.days = {labels: labels, data: data};
      this.days = {labels: labels, datasets: [{data: data, backgroundColor: ['#436294', '#5B76A1', '#7289AF', '#8A9DBC', '#A1B1C9', '#B9C4D7', '#D0D8E4']}]};

      labels = [];
      data = [];
      holidayData.forEach(v=>{
        labels.push(v.name);
        data.push(v.total);
      });
      // this.days_off = {labels: labels, data: data};
      this.days_off = {labels: labels, datasets: [{data: data, backgroundColor: ['#436294', '#5B76A1', '#7289AF', '#8A9DBC', '#A1B1C9', '#B9C4D7', '#D0D8E4']}]};
    },
    async getListProduct () {
      const params = `storeId=${this.store_id}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/product?${params}`);
      const products = result.products;
      this.products = [];
      products.forEach(v=>{
        this.products.push({
          product_code: v.code,
          product_name: v.name,
          count_supplier: v.supplier,
          count_store: v.store
        });
      });
    },
    async getOrderCurrentDay () {
      const params = `storeId=${this.store_id}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/orderCurrentDay?${params}`);
      const orderCurrentDay = result.orderCurrentDay;
      this.orders = [];
      orderCurrentDay.forEach(v=>{
        this.orders.push({
          page: v.code,
          visitors: v.customerName,
          unique: NumberUtils.toDecimalFormat(v.total),
          bounceRate: v.delivery_address
        });
      });
    },
    async getListStore () {
      const params = `storeId=${this.store_id}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/store?${params}`);
      const stores = result.stores;
      this.stores = [];
      stores.forEach(v=>{
        let progressType = "gradient-danger"
        if(v.perProductOfStore>50&&v.perProductOfStore<60){
          progressType = "gradient-warning";
        }else if(v.perProductOfStore>60&&v.perProductOfStore<70){
          progressType = "gradient-yellow";
        }else if(v.perProductOfStore>70&&v.perProductOfStore<80){
          progressType = "gradient-info";
        }else if(v.perProductOfStore>80&&v.perProductOfStore<90){
          progressType = "gradient-primary";
        }else if(v.perProductOfStore>90){
          progressType = "gradient-success";
        }
        this.stores.push({
          name: v.name,
          visitors: v.supplier,
          progress: parseFloat(v.perProductOfStore),
          progressType: progressType,
        });
      });
    },
    async getListUpsale () {
      const params = `storeId=${this.store_id}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/upsale?${params}`);
      const upsales = result.upSales;

      this.upsales = [];
      upsales.forEach(v=>{
        this.upsales.push({
          order_code: v.orderCode,
          customer_name: v.customerName,
          product_code: v.productCode
        });
      });
    },
    async getListOrder () {
      const dateFrom = DateUtils.dateFormat(this.start, "YYYY-MM-DD");
      const dateTo = DateUtils.dateFormat(this.end, "YYYY-MM-DD");
      const params = `storeId=${this.store_id}&dateFrom=${dateFrom?dateFrom:''}&dateTo=${dateTo?dateTo:''}`;
      const result = await this.HttpServices.getMasterData(`/ab_dashboard/dashboard/order?${params}`);
      const orderDay = result.orderDay;
      const orderWeek = result.orderWeek;
      const orderMonth = result.orderMonth;

      const dayly = JSON.parse(JSON.stringify(this.dayly));
      const weekly = JSON.parse(JSON.stringify(this.weekly));
      const monthly = JSON.parse(JSON.stringify(this.monthly));

      orderDay.forEach(v=>{
        const orderBy = `${v.orderby}.00`;
        let index = dayly.labels.indexOf(orderBy);
        dayly.data[0].splice(index, 1, v.store);
        dayly.data[1].splice(index, 1, v.supplier);
      });

      orderWeek.forEach(v=>{
        const orderBy = parseInt(v.orderby);
        weekly.data[0].splice(orderBy, 1, v.store);
        weekly.data[1].splice(orderBy, 1, v.supplier);
      });

      orderMonth.forEach(v=>{
        const orderBy = parseInt(v.orderby)-1;
        monthly.data[0].splice(orderBy, 1, v.store);
        monthly.data[1].splice(orderBy, 1, v.supplier);
      });

      this.chartDailyData.labels = dayly.labels;
      this.chartDailyData.datasets[0].data = dayly.data[0];
      this.chartDailyData.datasets[1].data = dayly.data[1];

      this.chartWeeklyData.labels = weekly.labels;
      this.chartWeeklyData.datasets[0].data = weekly.data[0];
      this.chartWeeklyData.datasets[1].data = weekly.data[1];

      this.chartMonthlyData.labels = monthly.labels;
      this.chartMonthlyData.datasets[0].data = monthly.data[0];
      this.chartMonthlyData.datasets[1].data = monthly.data[1];
    },
    disabledStartDate(date) {
      if(this.end){
        return date > this.end;
      }
      return false;
    },
    disabledEndDate(date){
      if(this.start){
        return date < this.start;
      }
      return false;
    },
    disabledSaleStartDate(date) {
      if(this.sale_end){
        return date > this.sale_end;
      }
      return false;
    },
    disabledSaleEndDate(date){
      if(this.sale_start){
        return date < this.sale_start;
      }
      return false;
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    pie,
    areaChart,
    barChart
  },
  beforeDestroy () {
    if(this.dataInterval){
      clearInterval(this.dataInterval);
    }
  },
  async mounted() {
    const result = await this.HttpServices.getMasterData(`/master/getStores`);
    this.item_stores = result;
    if(this.item_stores.length>0){
      this.store_id = this.item_stores[0].id;
    }
    await this.searchDataHandler();
    this.dataInterval = setInterval(() => {
      this.searchDataHandler();
    }, 180000);
  },
}
</script>
