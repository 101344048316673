
<script>
import { Bar } from "vue-chartjs";
export default {
  name: 'dashboard-view-bar-chart',
  extends: Bar,
  props: [
    'item',
    'funcs'
  ],
  mounted () {
    console.log('item', this.item)
    this.renderChart({
      labels: this.item.labels,
      datasets: [
        {
          label: "สั่งซื้อ",
          borderColor: "#709D9B",
          pointBackgroundColor: "white",
          borderWidth: 1,
          pointBorderColor: "white",
          backgroundColor: '#AAE4E1',
          data: this.item.data[0]
        },
        {
          label: "ขาย(รวม Vat)",
          borderColor: "#709D9B",
          pointBackgroundColor: "white",
          pointBorderColor: "white",
          borderWidth: 1,
          backgroundColor: '#D0F0EE',
          data: this.item.data[1]
        }
      ]
    }, {responsive: true, maintainAspectRatio: false})
  }
}
</script>